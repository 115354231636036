html, body, * {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

html {
  font-size: 14px;
  font-family: 'DM Sans', Arial, serif;
  color: #10242C;

  @include minTablet {
    font-size: 14px;
  }

  @include minDesktopSmall {
    font-size: 14px;
  }

  @include minDesktopLarge {
    font-size: 14px;
  }
}

body {
  margin: 0;

  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

main {
  min-height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  margin: 5px 0;
  font-size: 14px;
  white-space: pre-line;

  a {
    color: #7CC9C6;
  }
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.space-evenly {
  justify-content: space-evenly !important;
}

.space-between {
  justify-content: space-between;
}

.space-between-important {
  justify-content: space-between !important;
}

.listType {
  display: flex;
  align-items: center;

  >img {
    height: 15px;
    margin-right: 5px;
  }
}

.no-margin {
  margin: 0 !important;
}

// Workaround for some special cases [ Use this if max-content doesn't work for some reason ]
.max {
  max-width: max-content !important;
  width: 100%;
}

.max-content {
  min-width: max-content !important;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none !important;
}

.fw-bold {
  font-weight: bold;
}

.fw-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-large {
  font-size: 20px;
  font-weight: bold;
}

.center-middle {
  width: max-content;
  margin: 0 auto;
}

.align-end {
  align-self: end;
}

.align-middle {
  align-items: center;
}

.color-white {
  color: #ffffff;
}

.color-primary {
  color: #10242C !important;
}

.color-secondary {
  color: #7CC9C6 !important;
}

.background-primary {
  background: #10242C !important;
}

.background-secondary {
  background: #7CC9C6 !important;
}

.hidden {
  display: none !important;
}

.p0 {
  padding: 0 !important;
}

.p5 {
  padding: 5px !important;
}

.p10 {
  padding: 10px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mb40 {
  margin-bottom: 40px;
}

.p40 {
  padding: 40px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.bd-bottom {
  border-bottom: 1px solid #EAF6F6;
}

.mb130 {
  margin-bottom: 65px;

  @include minDesktopSmall {
    margin-bottom: 130px;
  }
}

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.m80 {
  margin: 80px;
}

.mt100 {
  margin-top: 50px;

  @include minDesktopSmall {
    margin-top: 100px;
  }
}

.mb10 {
  margin-bottom: 10px;

  @include minDesktopSmall {
    margin-bottom: 10px;
  }
}

.float-right {
  float: right;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.mb10 {
  margin-bottom: 10px;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.mb30 {
  margin-bottom: 15px;

  @include minDesktopSmall {
    margin-bottom: 30px;
  }
}

.mb40 {
  margin-bottom: 20px !important;

  @include minDesktopSmall {
    margin-bottom: 40px !important;
  }
}

.mb60 {
  margin-bottom: 30px;

  @include minDesktopSmall {
    margin-bottom: 60px;
  }
}

.mb70 {
  margin-bottom: 35px;

  @include minDesktopSmall {
    margin-bottom: 70px;
  }
}

.mb45 {
  margin-bottom: 30px;

  @include minDesktopSmall {
    margin-bottom: 45px;
  }
}

.mb50 {
  margin-bottom: 25px;

  @include minDesktopSmall {
    margin-bottom: 50px;
  }
}

.pb50 {
  padding-bottom: 25px;

  @include minDesktopSmall {
    padding-bottom: 50px;
  }
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 25px;

  @include minDesktopSmall {
    margin-top: 50px;
  }
}

.mt70 {
  margin-top: 35px;

  @include minDesktopSmall {
    margin-top: 70px;
  }
}

.mt30 {
  margin-top: 15px;

  @include minDesktopSmall {
    margin-top: 30px;
  }
}

.ml-auto {
  margin-left: auto;
}

.ml10 {
  margin-left: 10px;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr50 {
  margin-right: 50px;
}

.pt50 {
  padding-top: 25px;

  @include minDesktopSmall {
    padding-top: 50px;
  }
}

.w140 {
  width: 140px;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 100%;

  @include minDesktopSmall {
    width: 50%;
  }
}

.mb0 {
  margin-bottom: 0 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

[class*="col-"] {
  width: 100%;
}

@include minTablet {
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col-sm-1 {
    width: 8.333333335%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .justify-content-center-lg {
    justify-content: center;
  }
}

@include maxMobile {
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col-sm-1 {
    width: 8.333333335%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }
}

.np {
  padding: unset !important;
}

.d-flex {
  display: flex;
}

.gap-10 {
  gap: 10px;
}

.d-inline-flex {
  display: inline-flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-arround {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 1;
}

.flex-grow-3 {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-repsonsive-2 {
  height: 100%;
  width: auto;
}

.rotate-180 {
  transform: rotate(180deg);
}

.slick-slide div {
  outline: none;
}

.react-datepicker {
  display: flex !important;
  flex-direction: column-reverse;

  &__tab-loop {
    position: absolute;
  }

  &__input-container {
    display: block !important;

    input {
      background: #F6F6F6;
      border-radius: 8px;
      border: none;
      padding: 0;
      outline: none;
      font-weight: 700;
      font-family: 'DM Sans', Arial, serif;
      max-width: 100%;

      &.only-time {
        background-image: url("../images/select-hour-icon.svg") !important;
        background-repeat: no-repeat !important;
        background-position: 90% center !important;

        &[disabled] {
          background-image: url("../images/select-hour-icon-disabled.svg") !important;
          box-shadow: inset 0 0 0 1px #7CC9C6 !important;
          color: #7CC9C6 !important;
          cursor: not-allowed !important;
          -webkit-text-fill-color: #7CC9C6 !important;
          opacity: 1;
        }
      }
    }
  }
}

.border-round-grey {
  border: 2px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 30px;
}

.fs12 {
  font-size: 12px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.desktop-visible {
  display: none;

  @include minDesktopSmall {
    display: block;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 18px;
  left: 15px;
  top: 16px;
  outline: none;
}

#react-burger-menu-btn {
  outline: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000000;
  height: 2px !important;
  opacity: 1 !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #000000;
}

.bm-white-burger-bars {
  background: #ffffff;
}

.bm-white-burger-bars-hover {
  background: #ffffff52;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  top: 47px !important;
  right: 45px !important;
  cursor: pointer;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #000000;
  padding: 15px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #000000;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  outline: none;
  position: relative;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

html,
body,
#root,
#outer-container {
  height: 100%;
}

.form-group {
  position: relative;
  margin-bottom: 10px;

  &--select {
    position: relative;

    label {
      display: block;
      font-size: 10px;
      color: #000000;
      opacity: 0.5;
      z-index: 3;
      position: relative;
      pointer-events: none;
      padding-left: 15px;
      top: 5px;
    }
  }

  &--datepicker {
    border-radius: 8px;
    //padding: 7px 20px;

    label {
      display: block;
      font-size: 10px;
      color: #000000;
      opacity: 0.5;
      z-index: 3;
      position: relative;
      pointer-events: none;
      padding-left: 15px;
      top: 5px;
    }
  }

  &--filter {
    display: flex;
    align-items: center;

    &__btn {
      display: flex;
      font-weight: 700;
      cursor: pointer;
      margin-bottom: 20px;
      min-width: max-content;
    }

    &__active-filters {
      background: #000000;
      color: #ffffff;
      width: 20px;
      height: 20px;
      font-size: 11px;
      text-align: center;
      padding: 2px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'DM Sans';
    }
  }
}

.pre-line {
  white-space: pre-line;
}

.nowrap {
  white-space: nowrap;
}

.form-content {
  height: calc(100vh - 250px);
  max-height: 555px;
  overflow-y: auto;
  padding: 0 30px;
}

.form-content-small {
  padding: 10px 40px;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 75px;
  border-top: 1px solid #EAF6F6;
  padding: 10px;
}

.reset-to-default {
  position: absolute;
  left: 40px;
  text-decoration: none;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
}

@media screen and (max-width:540px) {
  .reset-to-default {
    left: 20px;
  }
}

.form-group {
  position: relative;
  margin-top: 0;
  margin-bottom: 20px;

  label {
    font-size: 14px;
    color: #cccccc;
    position: absolute;
    pointer-events: none;
    top: 17px;
    left: 2px;
    transition: all 0.1s ease;
    opacity: 0;

    &.validLocation {
      top: 9px;
      bottom: 0;
      opacity: 1;
      font-size: 10px;
      color: #000000;
    }

    &.valid {
      top: 5px;
      bottom: 0;
      left: -4px;
      opacity: 1;
      font-size: 10px;
      color: #000000;
    }

    &.disabled {
      color: hsl(0, 0%, 60%);
    }

    &.align {
      left: 2px !important;
    }
  }

  svg path {
    fill: #000000;
  }

  input:focus~label,
  input:not(:focus):valid~label {
    top: -15px;
    bottom: 0px;
    left: 0px;
    font-size: 11px;
    opacity: 1;
    color: #404040;
  }
}

.dz-message {
  outline: none;
}

@include minDesktopSmall {

  .login,
  .register,
  .forgot-password,
  .reset-password,
  .set-password,
  .activate-account {
    header {
      .inner {
        justify-content: center;

        nav,
        .auth {
          display: none;
        }
      }
    }
  }
}

.item__text {
  white-space: initial;
}


@media screen and (max-width:767px) {
  .ReactModalPortal {
    .ReactModal__Content {
      padding: 20px !important;
      max-width: 100% !important;

      @include minTablet {
        max-height: calc(100vh - 50px);
      }
    }
  }
  .hidden-mobile {
    display: none;
  }
}

@media screen and (min-width:768px) {
  .hidden-desktop {
    display: none !important;
  }
  .ReactModalPortal {
    .ReactModal__Content {
      //max-height: calc(100vh - 50px);
    }
  }
}

// friendly matches
.player-match-status {
  img {
    margin-right: 5px;
  }

  &__accepted {
    color: #39D377;
  }

  &__declined {
    color: #F42B03;
  }

  &__noMatch {
    color: #F42B03;
  }

  &__withdrawn {
    color: #F42B03;
  }

  &__pending {
    color: #898b8a;
  }
}

.rdp {
  //--rdp-cell-size: 30px !important;
  --rdp-accent-color: #10242C !important;
}

.rdp-head_cell {
  color: #78ADAB;
}


.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.white-dots {
 span {
   background: #ffffff !important;
 }
}